import { Box, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

function Preloader(){

    const theme = useColorModeValue("light", "dark");

    const boxColor = localStorage.getItem("chakra-ui-color-mode") === "light" ? "dark" : "light";
    const [dis, setDis] = useState("block");

    useEffect( () => {
        setTimeout(() => {
            setDis("none");
        }, 1200)
    });

    return (
        <Box display={dis} bg={theme} gap="0" w="100vw" h="100vh" zIndex="999" pos="fixed" top={0} left={0}>

            <Box w="70%" h="100%" className="wallleft" bg={theme} transitionDuration="400ms" pos="absolute" top="0" left="-20%" clipPath="polygon(0 0, 100% 0, calc(100% - 200px) 100%, 0 100%)" transform="translateX(101px)">

                <Text bg="accent" className="aniLeft" borderRadius="8px 0 0 8px" pr="30px" pos="absolute" top="50%" right="100px" clipPath="polygon(0 0, 100% 0, calc(100% - 40px) 100%, 0 100%)" transform="translateY(calc(-50% + 30px))">
                    <Icon as={FaAngleLeft} transform="scaleX(1.2)" fontSize="5em" color={theme} />
                </Text>

            </Box>

            <Box w="70%" h="100%" className="wallright" bg={theme} transitionDuration="400ms" pos="absolute" top="0" right="-20%" clipPath="polygon(200px 0, 100% 0, 100% 100%, 0 100%)" transform="translateX(-101px)">

                <Text bg={boxColor} className="aniRight" borderRadius="0 8px 8px 0" pl="30px" pos="absolute" top="50%" left="100px" clipPath="polygon(40px 0, 100% 0, 100% 100%, 0 100%)" transform="translateY(calc(-50% - 30px))">
                    <Icon as={FaAngleRight} transform="scaleX(1.2)" fontSize="5em" color={theme} />
                </Text>

            </Box>

        </Box>
    )
}

export default Preloader;
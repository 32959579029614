import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, HStack, IconButton, Image, Text, VStack, useColorModeValue, useDisclosure, useMediaQuery} from "@chakra-ui/react";
import { FaBars } from "react-icons/fa";
import { useEffect, useState } from "react";
import ColorModeSwitcher from "../ColorModeSwitcher";
import { ArrowUpIcon } from "@chakra-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";
import logo_dark from "../images/dark_logo.webp";
import logo_light from "../images/light_logo.webp";

function Nav ({prop, set, refs}) {

    const [isMobile] = useMediaQuery("(max-width: 1200px)");

    const navigation = useNavigate();
    const location = useLocation();

    function navigate(path){
        window.scroll({top:0});
        navigation(path);
    }

    const logo = useColorModeValue(logo_light, logo_dark);

    const theme = useColorModeValue("light", "dark");

    const gradShadeHover = {
        light: "linear(140deg, rgba(0, 0, 0, .05), rgba(0, 0, 0, 0))",
        dark: "linear(320deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))"
    }

    const gradHover = useColorModeValue(gradShadeHover.light, gradShadeHover.dark);
    

    const nav = [
        {name: "HOME", link: null}, 
        {name: "FEATURES", link: refs.feature}, 
        {name: "PORTFOLLIO", link: refs.portfolio}, 
        // {name: "EXPERTIES", link: refs.experties}, 
        {name: "OUR TEAM", link: refs.team},
        {name: "QUOTE", link: "/quote"},
        {name: "TESTIMONIAL", link: refs.testimonial}, 
        {name: "BLOG", link: refs.blog},
        {name: "CLIENTS", link: "/clients"}, 
        {name: "CONTACTS", link: refs.contact}
    ];

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [pos, setPos] = useState(0);
    const [top, setTop] = useState(0);

    const colors = {
        dark: '#212428CC',
        light: "#ecf0f3CC",
    }

    const navColor = useColorModeValue(colors.light, colors.dark);

    function handleScroll (ref) {
        if(ref[0] === "/"){
            navigate(ref);
            window.scroll({top:0});
        }else{
            if(location.pathname !== "/"){
                navigate("/");
                setTimeout(()=>{
                    ref.current.scrollIntoView();
                }, 500)
            }else{
                ref.current.scrollIntoView({ behavior: "smooth" });
            }
        }
    }
    
    function scrollToTop(homepage=true){
        if(location.pathname !== "/" && homepage){
            navigate("/");
        }
        window.scroll({top:0, behavior: "smooth"});
    }

    function handleNavClick(item){
        item.name === "HOME" ? scrollToTop() : handleScroll(item.link);
        setTimeout(()=>{
            onClose();
        }, 1000);
    }

    useEffect(()=>{
        window.addEventListener('scroll', (event) => {
            if(window.scrollY > 620){
                setPos("sticky");
            }else{
                setPos("relative");
            }
            if(window.scrollY < 200 || window.scrollY > 620){
                setTop("0");
            }else{
                setTop("-70px")
            }
        });
    }, [])

    const [isTab] = useMediaQuery("(min-width: 480px)");

    function ScrollTopBtn(){

        const [isDown, setIsDown] = useState(false);

        useEffect( () => {
            window.addEventListener('scroll', event => {
                setIsDown(window.scrollY > 1000);
            });
        }, [])

        return (
            <IconButton 
                onClick={() => scrollToTop(false)}
                aria-label="scroll_to_top"
                pos="fixed"
                right="20px"
                transition="all 250ms ease-out"
                variant="filled"
                boxSize="2em"
                fontSize="1.5em"
                fontWeight="800"
                borderRadius="50%"
                zIndex="99"
                boxShadow={prop.shadow}
                icon={<ArrowUpIcon />}
                className={`scrollTop ${isDown ? "active": ""}`}
                backdropFilter='auto' backdropBlur='2px'
                _hover={{color:"accent"}}
            />
        )
    }

    return (
        <>

        {/* scroll to top btn */}
        <ScrollTopBtn />

        <HStack backdropFilter='auto' backdropBlur='2px' justifyContent="end" p="10px 40px" bg={navColor} zIndex="2" pos={pos} top={top} transitionDuration="400ms" > 

            {/* Logo */}
            <Box cursor="pointer" onClick={scrollToTop} pos="absolute" left="40px" top="5px">
                <Image alt="logo" src={logo} w={500*0.15} h={389*0.15} />
            </Box>
            {/* navs */}

            <HStack gap={{base:"1em", lg:"2em"}}>
                <HStack gap="25px">

                {
                    ! isMobile ? 

                        nav.map(( item, index) => {
                            return (
                                <Text key={index} onClick={item.name === "HOME" ? scrollToTop : () => handleScroll(item.link)} fontSize=".8em" cursor="pointer" transition="all 350ms ease-out" _hover={{color:"accent"}} >{item.name}</Text>
                            )
                        })
                    
                    : null
                }
                </HStack>

                <ColorModeSwitcher set={set} />

                {
                    isTab ?

                    <Button bgGradient={prop.grad} boxShadow={prop.shadow} variant='filled' fontWeight='400' p="25px 30px" rounded="lg" color="accent"
                    _hover={{
                        transform:"translateY(-2px)",
                        bgGradient:gradHover
                    }}
                    onClick={() => navigate("/quote")}>
                        Got a Project?</Button>
                    : null
                }

                {
                isMobile ? 
                    <IconButton
                    aria-label="hamburger"
                    boxShadow={prop.shadow}
                    bgGradient={prop.grad}
                    // transition="all 300ms"
                    borderRadius="50%"
                    color="accent"
                    variant="filled"
                    w="50px"
                    h="50px"
                    icon={<FaBars />}
                    onClick={onOpen}
                    />
                : null
                }
            </HStack>

            <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="xs" >

                {/* <DrawerOverlay /> */}

                <DrawerContent bg={theme} p="10px 10px">

                    <DrawerCloseButton boxShadow={prop.shadow} borderRadius="50%" color="accent" p="20px" top="20px" />
                    <DrawerHeader>
                        {/* <Image src={logo} boxSize="50px" /> */}
                        <Box w="50px">
                            <Image alt="logo" src={logo} />
                        </Box>
                    </DrawerHeader>
                    <DrawerBody>
                        <hr></hr>

                        <VStack alignItems="start" m="25px 0" gap="1.2em">
                            {
                                nav.map(( item, index) => {
                                    return (
                                        <Text 
                                        key={index} 
                                        // onClick={item.name === "HOME" ? scrollToTop : () => handleScroll(item.link)} 
                                        onClick={() => handleNavClick(item)}
                                        fontSize="md" 
                                        fontWeight="500" 
                                        cursor="pointer" 
                                        transition="all 350ms ease-out" 
                                        _hover={{color:"accent"}} >{item.name}</Text>
                                    )
                                })
                            }
                        </VStack>

                    </DrawerBody>

                </DrawerContent>

            </Drawer>

        </HStack>
        </>
    )
}

export default Nav;

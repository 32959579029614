import { Stack, Heading, Image, Text, VStack } from "@chakra-ui/react";
import hasnain from "../images/team/hasnain.webp";
import rabnawaz from "../images/team/rabnawaz.webp";
import sami from "../images/team/sami.webp";
import team_1 from "../images/team/team-1.webp";
import team_2 from "../images/team/team-2.webp";


function Team({prop}){

    const team = [
        {
            img: rabnawaz,
            name: "Rabnawaz",
            pos: "Founder/Wordpress"
        },
        {
            img: sami,
            name: "Abdul Sami",
            pos: "Mern Stack/Laravel"
        },
        {
            img: hasnain,
            name: "Hasnain",
            pos: "Django/React"
        },
        {
            img: team_1,
            name: "Abdul Wahab",
            pos: "Flutter Developer"
        },
        {
            img: team_2,
            name: "Fahad",
            pos: "SEO Optimization"
        }
    ]

    function Card({item}){

        return(
            <VStack>
                <Image src={item.img} loading="lazy" alt={item.name} width="150px" height="150px" rounded="50%" mb="10px" />
                <Text fontSize="xl" fontWeight="bold">{item.name}</Text>
                <Text fontSize="md" color="gray">{item.pos}</Text>
            </VStack>
        )
    }

    return(
        <VStack ref={prop.ref} p="5em 0">
            {/* Heading */}
            <VStack mb="50px">
                <Text color="accent" fontWeight="800">MEET THE TEAM</Text>
                <Heading size="xl">Our Team</Heading>
            </VStack>

            <Stack direction={{base:"column",sm:"row"}} w="100%" justifyContent="center" gap={{base:"1.5em",sm:"5em"}} flexWrap="wrap" p={{base:"0 20px", lg:"0 1em"}}>
                {
                    team.map((item, index) => {
                        return (
                            <Card key={index} item={item} />
                        )
                    })
                }
            </Stack>

        </VStack>
    )
}

export default Team;
import { Box, Divider, HStack, Heading, Icon, Image, Text, VStack } from "@chakra-ui/react";
import { useInView } from "react-intersection-observer";
import { CalendarIcon } from "@chakra-ui/icons";
import { FaRegComments, FaUserAlt } from "react-icons/fa";
import blog1 from "../images/blog/blog-1.webp";
import blog2 from "../images/blog/blog-2.webp";
import blog3 from "../images/blog/blog-3.webp";
import { useNavigate } from "react-router-dom";


const Blog = ({prop}) => {

    const navigation = useNavigate();
    
    const blogs = [
        {
            img: blog1,
            date: "Nov 16, 2023",
            title: "CyberSecurity and Privacy.",
            by: "admin",
            comments: 0,
            link: "cyber-security"
        },
        {
            img: blog2,
            date: "March 15, 2023",
            title: "How Wireless Technology more Changing Business",
            by: "admin",
            comments: 0,
            link: "/"
        },
        {
            img: blog3,
            date: "January 6, 2022",
            title: "Thirty Surrogate mothers the trafficking",
            by: "admin",
            comments: 0,
            link: "/"
        }

    ]


    function navigate(link) {
        window.scroll({top:0});
        navigation(link);
    }

    function FCard({item}){

        const [ref, inView] = useInView({triggerOnce:true});

        return (
            <Box ref={ref} className={`card ${inView ? 'active' : ''}`} borderRadius="lg" boxShadow={prop.shadow} maxW="350px" p="20px">
                <Box w="300px" h="300px" overflow="hidden" borderRadius="lg">
                    <Image cursor="pointer" transition="all 400ms" onClick={() => navigate(item.link)} src={item.img} width={300} height={300} loading="lazy" alt={item.title}
                    _hover={{transform:"scale(1.1)"}} />
                </Box>
                <VStack alignItems="start">
                    <HStack m="10px 0" fontSize={12}>
                        <Icon as={CalendarIcon} color="accent" />
                        <Text color="grey">{item.date}</Text>
                    </HStack>
                    <Text fontWeight="600" cursor="pointer" onClick={() => navigate(item.link)}
                    _hover={{textDecoration:"underline"}}>
                        {item.title}
                        </Text>
                    <Divider />
                    <HStack w="100%" fontSize="sm" color="accent" justifyContent="space-between">
                        <HStack>
                            <Icon as={FaUserAlt} />
                            <Text color="grey">By {item.by}</Text>
                        </HStack>
                        <HStack>
                            <Icon as={FaRegComments} />
                            <Text color="grey">{item.comments} comments</Text>
                        </HStack>
                    </HStack>
                </VStack>
            </Box>
        )
    }


    return (
        <VStack ref={prop.ref} p="5em 0">
        {/* Heading */}
        <VStack mb="50px">
            <Text color="accent" fontWeight="800">Check our Blogs</Text>
            <Heading size="xl">BLOGS</Heading>
        </VStack>

        <HStack w="100%" justifyContent="center" gap="2em" flexWrap="wrap" p={{base:"0 20px", lg:"0 1em"}}>
            {
                blogs.map((item, index) => {
                    return (
                        <FCard key={index} item={item} />
                    )
                })
            }
        </HStack>

    </VStack>

    )
}

export default Blog;
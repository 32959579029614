import { Stack, Heading, Text, VStack, HStack, Box, FormControl, FormLabel, Input, Textarea, Button, IconButton, useColorModeValue, Image, useToast} from "@chakra-ui/react";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import vid from "../images/contact_v.webm";
import fiverrD from "../images/fiverr.svg";
import fiverrL from "../images/fiverr_light.svg";
import upworkD from "../images/upwork.svg";
import upworkL from "../images/upwork_light.svg";
import emailjs from "@emailjs/browser";
import { useInView } from "react-intersection-observer";

function Contact({prop}) {

    const Fiverr = useColorModeValue(fiverrL, fiverrD);
    const Upwork = useColorModeValue(upworkL, upworkD);

    const socialIcons = [
        {icon:FaFacebookF,link:"https://web.facebook.com/profile.php?id=61552528186983"}, 
        {icon:FaTwitter, link:"#"}, 
        {icon:FaLinkedinIn, link:"https://www.linkedin.com/in/rabnawazabbasi/"}
    ];

    const socialIconsSVG = [
        {icon:Fiverr, name:"fiverr", link:"https://www.fiverr.com/rabnawazasif?up_rollout=true"}, 
        {icon:Upwork, name:"upwork", link:"https://www.upwork.com/freelancers/~01f1955f5659e452d6?viewMode=1"}, 
    ];

    const inputBG = useColorModeValue("#fdfefe", "#191b1e")
    const inputBorder = useColorModeValue("2px solid #dce1e4", "2px solid #191b1e")
    const inputShade = useColorModeValue("none", "1px 4px 2px -3px rgba(0,0,0,0.7) inset, -1px -3px 3px -2px rgba(255, 255, 255, 0.2) inset");

    function Formcontrol ({label, type="text", t=false, f_name}) {
        return(
            <FormControl>
                <FormLabel fontSize="sm" textTransform="uppercase">{label}</FormLabel>
                { t ? 
                    <Textarea className="inp" name={f_name} boxShadow={inputShade} border={inputBorder} bg={inputBG} resize="none" focusBorderColor="accent" minH="180px" p="15px"></Textarea>
                :
                    <Input className="inp" name={f_name} boxShadow={inputShade} border={inputBorder} bg={inputBG} type={type} focusBorderColor="accent" h="55px" p="0 15px" />
                }
            </FormControl>
        )
    }


    const service_id = process.env.REACT_APP_SERVICE_ID;
    const template_id = process.env.REACT_APP_TEMPLATE_ID_CONTACT;
    const user_id = process.env.REACT_APP_USER_ID;

    const toast = useToast();

    function sendEmail (e) {
        e.preventDefault();


        emailjs.sendForm(service_id, template_id, e.target, user_id);
        toast({
            title: "Message Sent",
            duration: 4000,
            isClosable: true,
            status: "info",
            variant: "left-accent",
            position: "bottom-right"
        })
        for(let i of document.querySelectorAll(".inp")){
            i.value = "";
        }
    }

    const [leftRef, lInView] = useInView({triggerOnce:true});
    const [rightRef, rInView] = useInView({triggerOnce:true});
    
    return (
        <VStack ref={prop.ref} p="5em 0" w="100vw" overflow="hidden">
            {/* Heading */}
            <VStack mb="50px">
                <Text color="accent" fontWeight="800">How we may help you?</Text>
                <Heading size="xl">Contact</Heading>
            </VStack>

            <Stack flexDirection={{base:"column", md:"row"}} w="100%" justifyContent="center" gap="2em" p={{base:"0 20px", lg:"0 2em"}}>

                 {/* left sect */}
                <Box ref={leftRef} opacity={lInView ? 1 : 0} transform={`translateX(${lInView ? "0" : "-100%"})`} transition="all 800ms ease-out" bgGradient={prop.grad} boxShadow={prop.shadow} maxW={{base:"100%", lg:"400px"}} borderRadius="10px" p="20px">
                    <Box borderRadius="10px" overflow="hidden" mb="30px" w="100%">
                        <video width="100%" autoPlay loop>
                            <source src={vid} type="video/webm" />
                        </video>
                    </Box>
                    <Heading fontSize="2xl">Rabnawaz Abbasi</Heading>
                    <Text color="gray" m="15px 0">C.E.O & Founder</Text>
                    <Text color="gray" mb="15px">We are available for freelance work. Connect is via call or any freelance service.</Text>
                    <HStack fontSize={{base:"0.9em", sm:"1em"}}>
                        <Text color="gray">Phone:</Text>
                        <Text>+92 324 3251290</Text>
                    </HStack>
                    <HStack mb="20px" fontSize={{base:"0.9em", sm:"1em"}}>
                        <Text color="gray">Email:</Text>
                        <Text>rubnawaz.abbasi111@gmail.com</Text>
                    </HStack>
                    <Text color="gray">FIND US ON</Text>

                    <HStack gap="1.2em" mt="20px" flexWrap="wrap">
                            {
                                socialIcons.map((item, index) => {
                                    return (
                                        <Box p=".em" rounded="lg" transitionDuration="400ms" key={index} boxShadow={prop.shadow} _hover={{color:"accent"}}>
                                            <IconButton
                                            as="a"
                                            aria-label="link"
                                            target="_blank"
                                            _focus={{color:"accent"}}
                                            href={item.link}
                                            size="lg"
                                            icon={<item.icon />} 
                                            variant="filled" />
                                        </Box>
                                    )
                                })
                            }
                            {
                                socialIconsSVG.map((item, index) => {
                                    return (
                                        <Box as="a" target="_blank" href={item.link} p=".5em" rounded="lg" transitionDuration="400ms" key={index} boxShadow={prop.shadow} >
                                            <Image 
                                            alt={item.name}
                                            boxSize="2em"
                                            src={item.icon}  />
                                        </Box>
                                    )
                                })
                            }

                        </HStack>

                </Box>

                 {/* form */}
                <VStack ref={rightRef} opacity={rInView ? 1 : 0} transform={`translateX(${rInView ? "0" : "100%"})`} transition="all 800ms ease-out" as="form" onSubmit={sendEmail} w={{base:"100%",lg:"60%"}} bgGradient={prop.grad} boxShadow={prop.shadow} p="25px" borderRadius="10px">

                    <Stack flexDirection={{base:"column", lg:"row"}} w="100%">
                        <Formcontrol label="Your name" f_name="user_name" />
                        <Formcontrol label="Contact Number" f_name="user_phone" />
                    </Stack>

                    <Formcontrol label="Email" type="email" f_name="user_email" />

                    <Formcontrol label="subject" f_name="user_subject" />

                    <Formcontrol label="your message" t={true} f_name="user_message" />

                    <Button type="submit" transform="translateY(5px)" variant="filled" bgGradient={prop.grad} boxShadow={prop.shadow} fontWeight="400" w="100%" p="25px 0" focusBorderColor="transparent" transitionDuration="400ms" _hover={{transform: "translateY(-5px)"}} _focus={{boxShadow:"none", transform:"translateY(-5px)"}}>SEND MESSAGE</Button>
                </VStack>

            </Stack>

        </VStack>
    )
}

export default Contact;

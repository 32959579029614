import { Box, HStack, IconButton, Image, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import fiverrD from "../images/fiverr.svg";
import fiverrL from "../images/fiverr_light.svg";
import upworkD from "../images/upwork.svg";
import upworkL from "../images/upwork_light.svg";


function Footer({prop, refs}){

    const Fiverr = useColorModeValue(fiverrL, fiverrD);
    const Upwork = useColorModeValue(upworkL, upworkD);

    function handleScroll (ref) {
        ref.current.scrollIntoView({ behavior: "smooth" })
    }

    const socialIcons = [
        {icon:FaFacebookF,link:"https://web.facebook.com/profile.php?id=61552528186983"}, 
        {icon:FaTwitter, link:"#"}, 
        {icon:FaLinkedinIn, link:"https://www.linkedin.com/in/rabnawazabbasi/"}
    ];

    const socialIconsSVG = [
        {icon:Fiverr, name:"Fiverr", link:"https://www.fiverr.com/rabnawazasif?up_rollout=true"}, 
        {icon:Upwork, name:"Upwork", link:"https://www.upwork.com/freelancers/~01f1955f5659e452d6?viewMode=1"}, 
    ];

    const nav = [
        {name: "Contact Us", link: refs.contact},
        {name: "Features", link: refs.feature}, 
        {name: "Portfolio", link: refs.portfolio}, 
        {name: "Experties", link: refs.experties}, 
    ];

    const divid = useColorModeValue("rgba(45, 45, 45, .1)", "rgba(255, 255, 255, .1)");

    return (
        <VStack p="30px 0">
            <HStack gap="1em">
                {
                    socialIcons.map((item, index) => {
                        return (
                            <Box p=".em" rounded="lg" transitionDuration="400ms" key={index} boxShadow={prop.shadow} _hover={{color:"accent"}}>
                                <IconButton
                                as="a"
                                target="_blank"
                                aria-label="link"
                                _focus={{color:"accent"}}
                                href={item.link}
                                size="lg"
                                icon={<item.icon />} 
                                variant="filled" />
                            </Box>
                        )
                    })
                }
                {
                    socialIconsSVG.map((item, index) => {
                        return (
                            <Box as="a" target="_blank" href={item.link} p=".5em" rounded="lg" transitionDuration="400ms" key={index} boxShadow={prop.shadow} >
                                <Image 
                                alt={item.name}
                                boxSize="2em"
                                src={item.icon}  />
                            </Box>
                        )
                    })
                }
            </HStack>
            <HStack m="20px 0" gap={{base:"1em", md:"2em", lg:"3em", xl:"4em"}} fontSize="sm" color="gray">
                {
                    nav.map((item, index) => {
                        return (
                            <Text key={index} cursor="pointer" _hover={{color:"accent"}} onClick={() => handleScroll(item.link)}>{item.name}</Text>
                        )
                    })
                }
            </HStack>
            <Box w="30%" h="1px" bg={divid}></Box>
            <Text color="gray">&copy; 2023 - CrypticDevelopers</Text>
        </VStack>
    )
}

export default Footer;

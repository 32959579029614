import { Box, Image, Stack, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import banner from "../images/banner.svg";

function Landing(){

    // eslint-disable-next-line
    const words = [
        "IT Design & Consulting.", 
        "Digital Marketing & Web Solutions.", 
        "Mobile App Development.", 
        "Web Speed Optimization & SEO.", 
        "Agency that helps you to go ahead."
//        "Great companies are built on great products."
    ];
    const [word, setWord] = useState(words[0]);
    const i = useRef(1);
    const [active, setActive] = useState("cursor");

    const limiter = useRef(500);

    const theme = useColorModeValue("light", "dark");

    useEffect(()=>{
        const interval = setInterval(()=>{
            if(active === "cursor"){
                setWord(words[i.current]);
                i.current < words.length - 1 ? i.current = i.current + 1 : i.current = 0;
                setActive("cursor active"); // 0
                limiter.current = 2500;
            }else{
                setActive("cursor"); // 100%
                limiter.current = 500;
            }
        }, limiter.current);
        return () => clearInterval(interval);
    },[i, words, active]);

    const [ref, inView] = useInView({triggerOnce:true});

    return (
        <Stack pos="relative" flexDirection={{base:'column-reverse', md:"row"}} alignItems="center" justifyContent={{base:"center",lg:"space-between"}} h={{base:"auto", md:'90vh'}} p={{base: "0 15px",lg:"0 100px"}}>

            <VStack alignItems="start" w="100%">
                <Box pos="relative" fontSize={{base:"lg", md:"xl"}} overflow="hidden" pr="10px">
                  <Text fontWeight="400" color="accent">{word}</Text>
                  <Text as="span" w="100%" h="100%" pos="absolute" top="0" transition="all 400ms" bg={theme} className={active}><span className="blink">|</span></Text>
                </Box>
                <Text fontSize={{base:"xl", md:"3xl", lg:"4xl", xl:"5xl"}} fontWeight="700">Our Intelligent Technology<br />Solutions and Services</Text>
                <Text mt="15px" textAlign="left" w={{base:"auto",md:"50%"}}>A pioneering software corporation committed to delivering cutting-edge and all-encompassing digital solutions to both businesses and individuals.</Text>
            </VStack>

            <Image ref={ref} className={`banner ${inView ? "active" : ""}`} src={banner} alt="banner" pos={{base:"static",md:"absolute"}} right="-50px" m={{base:"30px 0", lg:"0"}}
            w={{base:250, md:400, lg:500, xl:600}}
            h={{base:250, md:400, lg:500, xl:600}}
            />

        </Stack>
    )
}

export default Landing;

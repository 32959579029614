import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { HStack, Heading, IconButton, Text, VStack, Image, Divider, Icon, Box, Stack} from "@chakra-ui/react";
import AgraTech from "../images/testimonial/agratech.webp";
import RBC from "../images/testimonial/RBC.webp";
import YY from "../images/testimonial/yy Logo.webp";
import { FaStar } from "react-icons/fa";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useEffect, useState } from "react";


function Testimonials ({prop}) {
// carousel auto scroll;
    const testimonials = [
        {
            img: AgraTech,
            platform: "Customer",
            name: "juanalvaroq",
            work: "AgraTech",
            time: "via upwork - Mar 4 2015 - Aug 30, 2021",
            description: "Rabnawaz exceeded my expectations while creating my website, Agratech. From start to finish, he demonstrated exceptional technical skills, effective communication, and punctuality. The website looks fantastic, functions flawlessly, and was delivered on time. Rabnawaz's pricing was reasonable, providing excellent value for his top-notch services. I highly recommend Rabnawaz Abbasi for anyone seeking a website expert. Thank you for bringing Agratech to life!"
        },
        {
            img: RBC,
            platform: "Customer",
            name: "menona616",
            work: "rbclogistics.com",
            time: "via upwork - Mar 4 2015 - Aug 30, 2021",
            description: "He worked wonders on our website, RBC Logistics, swiftly resolving the \"HTTPS Not Secure\" issue. His expertise shone throughout the project. Rabnawaz not only crafted a visually appealing website but ensured seamless functionality, elevating the user experience. What impressed us most was his technical prowess. He fixed the security issue efficiently, making our site safe and reliable. His communication was clear and professional, and he consistently met deadlines. His pricing was reasonable, offering excellent value for the quality of service."
        },
        {
            img: YY,
            platform: "Customer",
            name: "IqbalNagaria",
            work: "yyassociates.com",
            time: "via upwork - Mar 4 2015 - Aug 30, 2021",
            description: "Working with Rabnawaz Abbasi to create yyassociates.com has been an amazing experience for us. His ability to turn our ideas into a sleek and user-friendly real estate website is unmatched. His expertise ensured a secure, hassle-free experience. Communication was seamless, His pricing was competitive and fair. If you need a top-notch real estate site, Rabnawaz Abbasi is your go-to. He knows what he is doing, and yyassociates.com is proof of his excellence. Thanks, Rabnawaz, for bringing our vision to life!"
        }
    ]

    const [count, setCount] = useState(0);

    useEffect( () => {
        const interval = setInterval(()=>{
            count !== 2 ? setCount(c => c+1) : setCount(0);
        }, 5000)
        return () =>  clearInterval(interval);
    })

    const handleNext = () => {
        count < testimonials.length-1 ? setCount(count+1) : setCount(0);
    };
    
    const handlePrev = () => {
        count > 0 ? setCount(count-1) : setCount(testimonials.length-1);
    };

    function Testi({con}){
        // img, platform, name, position, work, time, description
        return (
            <Stack flexDirection={{base:"column", lg:"row"}} m="20px 0" gap="2em" w="100%" alignItems="end" transform={{base:"translateX(-20px)" ,lg:"translateX(15px)"}}>

                <Stack position="relative" flexDirection={{base:"row", lg:"column"}} alignItems={{base:"center", lg:"start"}} p="20px 15px" w={{base:"90%",  lg:"auto"}} h={{base:"auto", lg:"auto"}} rounded="lg" transitionDuration="400ms" boxShadow={prop.shadow}>
                    <Box w={{base:"170px",lg:"auto"}}>
                        <Image src={con.img} mb={{lg:"20px"}} alt={con.work} boxSize={{base:"170", lg:"auto"}} objectFit="contain" objectPosition="left" borderRadius="10px" />
                    </Box>
                    <VStack textAlign="left" alignItems="left">
                        <Text fontWeight="500" mb="10px" color="accent">{con.platform}</Text>
                        <Text fontSize="2xl" fontWeight="bolder">{con.name}</Text>
                    </VStack>
                </Stack>

                <VStack pos="relative" alignItems="start" boxShadow={prop.shadow} rounded="lg" p={{base:"15px 15px", md:"20px 30px"}} w={{base:"90%", lg:"60%"}}>
                    <Text fontSize="xl" fontWeight="bold">{con.work}</Text>
                    <HStack justifyContent="space-between" w="100%">
                        <Text textAlign="left" color="gray">{con.time}</Text>
                        <HStack gap="1px" fontSize=".8em" color="yellow.400" transitionDuration="400ms" boxShadow={prop.shadow} p="10px" rounded="lg">
                            <Icon as={FaStar} />
                            <Icon as={FaStar} />
                            <Icon as={FaStar} />
                            <Icon as={FaStar} />
                            <Icon as={FaStar} />
                        </HStack>
                    </HStack>
                    <Divider m="10px 0" />
                    <Text textAlign="left" fontSize={{base:"15px",lg:"md"}}>{con.description}</Text>
                </VStack>
                
            </Stack>

        )
    }

    return (
        <VStack ref={prop.ref} p="5em 0">
            {/* Heading */}
            <VStack mb="50px">
                <Text color="accent" fontWeight="800">WHAT CLIENTS SAY</Text>
                <Heading size="xl">Testimonial</Heading>
            </VStack>

            <HStack pos="relative" w="80%" justifyContent="center" p={{base:"0 20px", lg:"0 1em"}}>

                <HStack pos="absolute" right={{base:0,lg:"3em"}} top="0" gap="1em">
                    {
                        [{icon:ArrowBackIcon,btn:handlePrev, name:"arrow-left"}, {icon:ArrowForwardIcon,btn:handleNext, name:"arrow-right"}].map((item, index) => {
                            return(
                                <IconButton key={index} aria-label={item.name} onClick={item.btn} cursor="pointer" zIndex="9" size="lg" fontSize="1.4em" p="1.2em" variant="filled" boxShadow={prop.shadow} icon={<item.icon />} color="grey"
                                _hover={{
                                    color: "accent"
                                }} />
                            )
                        })
                    }
                </HStack>


                <Box w={{base:"90vw", lg:"80vw"}} mt={{base:"60px", lg:0}}>

                    <Carousel
                        
                        showArrows={false}
                        showThumbs={false}
                        autoPlay={false}
                        infiniteLoop={true}
                        showStatus={false}
                        swipeable={false}
                        selectedItem={count}
                        showIndicators={false}
                    >

                        {
                            testimonials.map((item, index) => {
                                return (
                                    <Testi con={item} key={index} />
                                    )
                                })
                            }

                    </Carousel>
                </Box>

            </HStack>

        </VStack>
    )
}

export default Testimonials;

import { FaMoon, FaSun } from 'react-icons/fa';
import {
    useColorModeValue,
    useColorMode,
    IconButton,
} from '@chakra-ui/react';


const shadowShade = {
    dark: "-5px -5px 13px rgba(255, 255, 255, .07), 5px 5px 13px rgba(0, 0, 0, .3)",
    light: "-5px -5px 13px rgba(255, 255, 255, 1), 5px 5px 13px rgba(0, 0, 0, .15)"
  }
  
  const gradShade = {
    light: "linear(140deg, rgba(0, 0, 0, .05), rgba(0, 0, 0, 0))",
    dark: "linear(140deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0))"
  } 

function ColorModeSwitcher ({set}) {
    const { toggleColorMode } = useColorMode();
    const text = useColorModeValue('dark', 'light');
    const SwitchIcon = useColorModeValue(FaMoon, FaSun);
  
    function handleClick(){
      toggleColorMode();
      set.setShadow(
        color => color === shadowShade.light ? shadowShade.dark : shadowShade.light
      );
      set.setGrad(
        color => color === gradShade.light ? gradShade.dark : gradShade.light
      )
    }
  
    return (
      <IconButton
        size="md"
        fontSize="lg"
        aria-label={`Switch to ${text} mode`}
        variant="ghost"
        cursor="pointer"
        color = {text === "dark" ? "dark" : "light"}
        onClick={handleClick}
        icon={<SwitchIcon />}
      />
    );
};


export default ColorModeSwitcher;
import { Box, HStack, Heading, Icon, Image, Text, VStack } from "@chakra-ui/react";
import { FaRegHeart } from "react-icons/fa";
import agratech from "../images/portfolio/agratech.webp";
import RBC from "../images/portfolio/RBC.webp";
import YY from "../images/portfolio/yy.webp";
import { useInView } from "react-intersection-observer";


function Portfolio ({prop}) {

    const cards = [
        {
            img: agratech,
            likes: 129,
            heading: "Web Development",
            description: "Discover our web development expertise and witness the transformative power of our work."
        },
        {
            img: RBC,
            likes: 184,
            heading: "Web Site Optimization",
            description: "We'll handle everything from app development process until it is time to make your project live."
        },
        {
            img: YY,
            likes: 178,
            heading: "Plugin Development",
            description: "We'll help you optimize your business processes to maximize profitability and eliminate unncecessary costs."
        }
    ]

    function FCard({item}){

        const [ref, inView] = useInView({triggerOnce:true});

        return (
            <Box ref={ref} className={`card ${inView ? 'active' : ''}`} borderRadius="lg" boxShadow={prop.shadow} maxW="350px" p="20px">
                <Image src={item.img} width={300} height={300} loading="lazy" alt={item.heading} borderRadius="lg" />
                <HStack justifyContent="space-between">
                    <Text color="accent" fontSize="sm" m="10px 0" fontWeight="800">{item.heading}</Text>
                    <HStack fontSize="sm">
                        <Icon as={FaRegHeart} />
                        <Text>{item.likes}</Text>
                    </HStack>
                </HStack>
                <Text>{item.description}</Text>
            </Box>
        )
    }
    

    return (
        <VStack ref={prop.ref} p="5em 0">
            {/* Heading */}
            <VStack mb="50px">
                <Text color="accent" textAlign="center" fontSize="sm" fontWeight="800">VISIT MY PORTFOLIO AND KEEP YOUR FEEDBACK</Text>
                <Heading size="xl">Our Portfolio</Heading>
            </VStack>

            <HStack w="100%" justifyContent="center" gap="2em" flexWrap="wrap" p={{base:"0 20px", lg:"0 2em"}}>
            {
                    cards.map((item, index) => {
                        return (
                            <FCard key={index} item={item} />
                        )
                    })
                }
            </HStack>

        </VStack>
    )
}

export default Portfolio;

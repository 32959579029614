import { Box, HStack, Heading, Icon, Text, VStack } from "@chakra-ui/react";
import { FaMobileAlt, FaCode, FaBook, FaCheckSquare, FaCss3 } from 'react-icons/fa';
import { BiCustomize, BiSupport } from "react-icons/bi";
import { GiGears } from "react-icons/gi";
import { AiOutlineFontSize } from "react-icons/ai";
import { useInView } from "react-intersection-observer";

function Features({prop}){

    const cards = [
        {
            icon: FaMobileAlt,
            heading: "Responsive Design",
            description: "This Template is fully responsive and mobile friendly design."
        },
        {
            icon: FaCode,
            heading: "Clean Markup",
            description: "Every code is supser organized, readable, and well commented"
        },
        {
            icon: FaBook,
            heading: "Well Documented",
            description: "Our Product is well documented for better understand about easy code customize"
        },
        {
            icon: FaCheckSquare,
            heading: "W3C Validate",
            description: "100% validate with W3C. so no Error are caused by backward browser compatibility."
        },
        {
            icon: GiGears,
            heading: "Customizable",
            description: "Every code is super organized, readable, and well commented"
        },
        {
            icon: FaCss3,
            heading: "Animate CSS",
            description: "We add animate CSS in our template. You can easily animate any element on your own"
        },
        {
            icon: BiCustomize,
            heading: "Mega Menu",
            description: "We Awesome Mega Menu used this template Done."
        },
        {
            icon: AiOutlineFontSize,
            heading: "Google Font",
            description: "Free Google Fonts are Used by us in this template"
        },
        {
            icon: BiSupport,
            heading: "Customer Support",
            description: "15 Hours Real Time Suport. We Provide quality work and support"
        }
    ]

    function FCard({icon, heading, description}){

        const [ref, inView] = useInView({triggerOnce:true});

        return (
            <Box ref={ref} className={`card ${inView ? 'active' : ''}`} boxShadow={prop.shadow} maxW={{base:"300px",md:"350px"}} h={{base:"auto",md:"300px"}} p={{base:"30px",md:"50px"}}>
                <Icon as={icon} color="accent" boxSize={8} />
                <Heading m="10px 0" size="md">{heading}</Heading>
                <Text>{description}</Text>
            </Box>
        )
    }

    return (
        <VStack ref={prop.ref} p="5em 0">
            {/* Heading */}
            <VStack mb="50px">
                <Text color="accent" fontWeight="800">FEATURES</Text>
                <Heading size="xl">What We Do</Heading>
            </VStack>

            <HStack w="100%" justifyContent="center" gap={{base:"20px",md:"2em"}} flexWrap="wrap" p={{base:"0 20px", lg:"0 1em"}}>
                {
                    cards.map((item, index) => {
                        return (
                            <FCard key={index} icon={item.icon} heading={item.heading} description={item.description} />
                        )
                    })
                }
            </HStack>

        </VStack>
    )
}

export default Features;

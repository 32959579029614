import React, { Suspense, useRef, useState } from 'react';
import {
  ChakraProvider,
  CircularProgress,
  Divider,
  Stack,
  extendTheme,
} from '@chakra-ui/react';
import { mode } from "@chakra-ui/theme-tools";
import Nav from './components/Nav';
import Landing from './components/Landing';
import Features from './components/Features';
import Portfolio from './components/Portfolio';
// import Experties from './components/Experties';
// import Quote from "./components/Quote";
// import Clients from "./components/Clients";
// import Bot from './components/Bot';
import Testimonials from "./components/Testimonial";
import Contact from './components/Contact';
import Team from './components/Team';
import Preloader from './components/Preloader';
import Footer from './components/Footer';
import "./styles/style.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import QuoteLead from './components/QuoteLead';
import Blog from './components/Blog';
// import CyberSecurity from './components/CyberSecurity';
// const Features = React.lazy(() => import("./components/Features"));
// const Portfolio = React.lazy(() => import("./components/Portfolio"));
// const Experties = React.lazy(() => import("./components/Experties"));
// const Testimonials = React.lazy(() => import("./components/Testimonial"));
// const Team = React.lazy(() => import("./components/Team"));
// const Contact = React.lazy(() => import("./components/Contact"));
const Quote = React.lazy(() => import("./components/Quote"));
const Clients = React.lazy(() => import("./components/Clients"));
const CyberSecurity = React.lazy(()=> import("./components/CyberSecurity"));


const shadowShade = {
  dark: "-5px -5px 13px rgba(255, 255, 255, .07), 5px 5px 13px rgba(0, 0, 0, .3)",
  light: "-5px -5px 13px rgba(255, 255, 255, 1), 5px 5px 13px rgba(0, 0, 0, .15)"
}

const gradShade = {
  light: "linear(140deg, rgba(0, 0, 0, .05), rgba(0, 0, 0, 0))",
  dark: "linear(140deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0))"
}

const theme = extendTheme({
  styles:{
    global:(props) => ({
      "html, body":{
        background: mode("light", "dark")(props),
        transitionProperty: "all",
        transitionDuration: "400ms"
      }
    })
  },
  config:{
    disableTransitionOnChange: false,
    initialColorMode: "dark",
    useSystemColorMode: true
  },
  fonts:{
    heading: "Poppins, sans-serif",
    body: "Montserrat, sans-serif"
  },
  colors: {
    dark: '#212428',
    light: "#ecf0f3",
    accent: "#ff014f"
  }
})

function App() {

  // const [readyBot, setReadyBot] = useState(false);

  // setTimeout(() => {
  //     setReadyBot(true);
  // }, 30000)

  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const [shadow, setShadow] = useState(colorMode === 'light' ? shadowShade.light : shadowShade.dark);
  const [grad, setGrad] = useState(colorMode === 'light' ? gradShade.light : gradShade.dark);

  const refs = {  
    feature: useRef(null),
    portfolio: useRef(null),
    team: useRef(null),
    experties: useRef(null),
    quote: useRef(null),
    testimonial: useRef(null),
    blog: useRef(null),
    clients: useRef(null),
    contact: useRef(null)
  }

  function Loader(){

    return(
      <Stack w="100vw" h="calc(100vh - 70px)" justifyContent="center" alignItems="center">
        <CircularProgress isIndeterminate size="100px" color="accent" />
      </Stack>
    )
  }

  return (
    <Router>

    <ChakraProvider theme={theme}>

        <Preloader />

        <Nav refs={refs} prop={{shadow:shadow, grad:grad}} 
        set={{setShadow:setShadow, setGrad:setGrad}} />

        <Routes>

          <Route exact path="/" element={
            <>
                <Landing />
                <Features prop={{ref:refs.feature, shadow:shadow, grad:grad}} />
                <Portfolio prop={{ref:refs.portfolio, shadow:shadow}} />
                {/* <Experties prop={{ref:refs.experties, shadow:shadow}} /> */}
                <QuoteLead />
                <Team prop={{ref:refs.team, shadow:shadow}} />
                <Testimonials prop={{ref:refs.testimonial, shadow:shadow}} />
                <Blog prop={{ref:refs.blog, shadow:shadow}} />
                <Contact prop={{ref:refs.contact, shadow:shadow, grad:grad}} />
            </>
              } 
          />
          <Route path="quote" element={
              <Suspense fallback={<Loader />}>
                <Quote prop={{ref:refs.quote, shadow:shadow}} />
              </Suspense>
          } />
          <Route path="clients" element={
              <Suspense fallback={<Loader />}>
                <Clients prop={{ref:refs.clients, shadow:shadow}} />
              </Suspense>
          } />
          <Route path="cyber-security" element={
            <Suspense fallback={<Loader />}>
              <CyberSecurity prop={{shadow:shadow}} />
            </Suspense>
          } 
          />
        
        </Routes>

        <Divider />
        <Footer refs={refs} prop={{shadow:shadow}} />

        {/* {
          readyBot ?
          <Bot />
          : null
        } */}

    </ChakraProvider>
    </Router>
  );
}

export default App;

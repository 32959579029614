import { Button, Stack, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";


const QuoteLead = () => {

    const navigate = useNavigate();

    function lead(){
        window.scroll({top:0});
        navigate("/quote");
    }

    const btnStyle = {borderWidth:"2px",borderColor:"light",bg:"transparent",color:"light",outline:"none"};

    return (
        <Stack flexDirection={{base:"column", lg:"row"}} alignItems="center" justifyContent="space-between" p={{base:"50px 20px",lg:"70px 140px"}} bg="accent" m="50px 0" gap={{base:"20px",lg:"0"}}>
            <Text color="light" textAlign={{base:"center",lg:"left"}} fontSize={{base:"md",md:"lg",lg:"2xl"}} fontWeight="800">Great Things in Business are Never<br />Done By One Person.</Text>
            <Button variant="filled" bg="light" w="auto" color="dark" borderRadius="5px" transition="all 250ms" borderWidth="2px" borderColor="light"
            onClick={lead} 
            _hover={btnStyle}
            _focus={btnStyle}
            >Purchase now</Button>
        </Stack>
    )
}

export default QuoteLead;